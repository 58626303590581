import logo from "../images/logo.png";
import BG from "../images/BG.png";
import BonusBoard from "../images/BonusBoard.png";
import Trophy from "../images/Trophy.png";
import BackgroundStudio from "../images/Background Studio.png";
import CatchphraseScreen from "../images/CatchphraseScreen.png";

const GameStates = {
    Loading: "loading",
    MainMenu: "main_menu",
    GameSelect: "game_select",
    Tutorial: "tutorial",
    RoundIntro: "round_intro",
    Playing: "playing",
    RevealAnswer: "reveal_answer",
    RoundSummary: "round_summary",
    EndGame: "end_game",
    SelectingTeams: "selecting_teams",
};

const CatchphraseServerOutMessages = {
    StartGameSelection: "catch_out_start_game_selection",
    StartTeamsSelection: "catch_out_start_teams_selection",
    StartRoundIntro: "catch_out_start_round_intro",
    StartPhrase: "catch_out_start_phrase",
    PlayerBuzzedIn: "catch_out_player_buzzed_in",
    PlayerGuessed: "catch_out_player_guessed",
    StartGridPhrase: "catch_out_start_grid_phrase",
    RevealAnswer: "catch_out_reveal_answer",
    StartRoundSummary: "catch_out_start_round_summary",
    CancelBuzzIn: "catch_out_cancel_buzz_in",
    BuzzerTimedOut: "catch_out_buzzer_timed_out",
    TeamSelected: "catch_out_team_selected",
    SkipRoundIntro: "catch_out_skip_round_intro",
    PlayerPassed: "catch_out_player_passed",
}

const CatchphraseServerInMessages = {
    GameStarted: "catch_in_game_started",
    FinishGameSelection: "catch_in_finish_game_selection",
    TeamSelected: "catch_in_team_selected",
    FinishTeamsSelection: "catch_in_finish_teams_selection",
    FinishRoundIntro: "catch_in_finish_round_intro",
    StartPhraseTimer: "catch_in_start_phrase_timer",
    PlayerBuzzedIn: "catch_in_player_buzzed_in",
    PlayerGuessed: "catch_in_player_guessed",
    FinishPhrase: "catch_in_finish_phrase",
    FinishRound: "catch_in_finish_round",
    FinishGridPhrase: "catch_in_finish_grid_phrase",
    FinishRoundSummary: "catch_in_finish_round_summary",
    ReachedEnd: "catch_in_reached_end",
    PlayAgain: "catch_in_catchphrase_game",
    SkipRoundIntro: "catch_in_skip_round_intro",
    Pass: "catch_in_pass",
}

const images = [
    logo,
    BG,
    BonusBoard,
    Trophy,
    CatchphraseScreen,
    BackgroundStudio,
];

const fonts = [
    { font: "Impact", path: `../fonts/Impact.ttf` },
    { font: "Futura-PT", path: `../fonts/Futura-PT.ttf` },
];


const WSErrorCodes = {
    1001: "Browser Tab Closing",
    1002: "Malformed framce received",
    1003: "Unsupported frame received",
    1006: "GS ungraceful shutdown / connection refused",
    1011: "Internal Error",
    1012: "Server Restarting",
    1013: "Try again later, server capacity full",
    1014: "Bad Gateway",
    4000: "Gameserver graceful shutdown",
    4050: "Player removed"
};

export {
    images,
    fonts,
    WSErrorCodes,
    GameStates,
    CatchphraseServerOutMessages,
    CatchphraseServerInMessages,
};