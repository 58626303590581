//SFX
import Buzzer from "audio/sfx/Buzzer.mp3";
import ButtonPress from "audio/sfx/ButtonPress.mp3";
import CorrectAnswer from "audio/sfx/CorrectAnswer.mp3";
import IncorrectAnswer from "audio/sfx/IncorrectAnswer.mp3";
import RoundEnd from "audio/sfx/RoundEnd.mp3";
import Winners from "audio/sfx/Winners.mp3";
import Tik from "audio/sfx/Tik.mp3";
import Tok from "audio/sfx/Tok.mp3";
import TimesUpBell from "audio/sfx/TimesUpBell.mp3";
import Pass from "audio/sfx/Pass.mp3";

//Music
import MenuMusic from "audio/music/MenuMusic.mp3";
import RoundOneMusic from "audio/music/RoundOneMusic.mp3";
import RoundTwoMusic from "audio/music/RoundTwoMusic.mp3";
import RoundThreeMusic from "audio/music/RoundThreeMusic.mp3";
import RoundSummaryMusic from "audio/music/RoundSummaryMusic.mp3";
import EndGameMusic from "audio/music/EndGameMusic.mp3";

//VO
import VO_Wecome from "audio/vo/Welcome.mp3";
import VO_TeamSelect from "audio/vo/TeamSelect.mp3";
import VO_RoundOneIntro from "audio/vo/RoundOneIntro.mp3";
import VO_RoundTwoIntro from "audio/vo/RoundTwoIntro.mp3"
import VO_RoundThreeIntro from "audio/vo/RoundThreeIntro.mp3";
import VO_RoundOneSummary from "audio/vo/RoundOneSummary.mp3";
import VO_RoundTwoSummary from "audio/vo/RoundTwoSummary.mp3";
import VO_RoundThreeSummary from "audio/vo/RoundThreeSummary.mp3";
import VO_IncorrectVariantOne from "audio/vo/IncorrectVariantOne.mp3";
import VO_IncorrectVariantTwo from "audio/vo/IncorrectVariantTwo.mp3";
import VO_IncorrectVariantThree from "audio/vo/IncorrectVariantThree.mp3";
//import VO_IncorrectVariantFour from "audio/vo/IncorrectVariantFour.mp3";
import VO_IncorrectVariantFive from "audio/vo/IncorrectVariantFive.mp3";
import VO_TimedOutVariantOne from "audio/vo/TimedOutVariantOne.mp3";
import VO_TimedOutVariantTwo from "audio/vo/TimedOutVariantTwo.mp3";
import VO_CorrectVariantOne from "audio/vo/CorrectVariantOne.mp3";
import VO_CorrectVariantTwo from "audio/vo/CorrectVariantTwo.mp3";
import VO_CorrectVariantThree from "audio/vo/CorrectVariantThree.mp3";
import VO_CorrectVariantFour from "audio/vo/CorrectVariantFour.mp3";
import VO_CorrectVariantFive from "audio/vo/CorrectVariantFive.mp3";
import VO_LockedOut from "audio/vo/LockedOut.mp3";
import VO_BonusBoardIntro from "audio/vo/BonusBoardIntro.mp3";
import VO_BonusBoardReturn from "audio/vo/BonusBoardReturn.mp3";
import VO_RoundTimesUpVariantOne from "audio/vo/RoundTimesUpVariantOne.mp3";
import VO_RoundTimesUpVariantTwo from "audio/vo/RoundTimesUpVariantTwo.mp3";
import VO_RoundTimesUpVariantThree from "audio/vo/RoundTimesUpVariantThree.mp3";
import VO_PhraseQuipVariantOne from "audio/vo/PhraseQuipVariantOne.mp3";
import VO_PhraseQuipVariantTwo from "audio/vo/PhraseQuipVariantTwo.mp3";
import VO_PhraseQuipVariantThree from "audio/vo/PhraseQuipVariantThree.mp3";
import VO_PhraseQuipVariantFour from "audio/vo/PhraseQuipVariantFour.mp3";
import VO_PhraseQuipVariantFive from "audio/vo/PhraseQuipVariantFive.mp3";
import VO_PhraseQuipVariantSix from "audio/vo/PhraseQuipVariantSix.mp3";
import VO_FirstPhraseIntro from "audio/vo/FirstPhraseIntro.mp3";
import VO_NextPhraseIntroVariantOne from "audio/vo/NextPhraseIntroVariantOne.mp3";
import VO_NextPhraseIntroVariantTwo from "audio/vo/NextPhraseIntroVariantTwo.mp3";
import VO_NextPhraseIntroVariantThree from "audio/vo/NextPhraseIntroVariantThree.mp3";
import VO_NextPhraseIntroVariantFour from "audio/vo/NextPhraseIntroVariantFour.mp3";
import VO_NextPhraseIntroVariantFive from "audio/vo/NextPhraseIntroVariantFive.mp3";
import VO_AllLockedGridIntro from "audio/vo/AllLockedGridIntro.mp3";
import VO_OneTeamGridIntro from "audio/vo/OneTeamGridIntro.mp3";
import VO_AllTeamsGridIntro from "audio/vo/AllTeamsGridIntro.mp3";
import VO_MidRoundQuipVariantOne from "audio/vo/MidRoundQuipVariantOne.mp3";
import VO_MidRoundQuipVariantTwo from "audio/vo/MidRoundQuipVariantTwo.mp3";
import VO_MidRoundQuipVariantThree from "audio/vo/MidRoundQuipVariantThree.mp3";
import VO_MidRoundQuipVariantFour from "audio/vo/MidRoundQuipVariantFour.mp3";
import VO_MidRoundQuipVariantFive from "audio/vo/MidRoundQuipVariantFive.mp3";
import VO_MidRoundQuipVariantSix from "audio/vo/MidRoundQuipVariantSix.mp3";
import VO_MidRoundQuipVariantSeven from "audio/vo/MidRoundQuipVariantSeven.mp3";
import VO_MidRoundQuipVariantEight from "audio/vo/MidRoundQuipVariantEight.mp3";
import VO_MidRoundQuipVariantNine from "audio/vo/MidRoundQuipVariantNine.mp3";
import VO_MidRoundQuipVariantTen from "audio/vo/MidRoundQuipVariantTen.mp3";
import VO_TimesNearlyUpVariantOne from "audio/vo/TimesNearlyUpVariantOne.mp3";
import VO_BuzzInQuipVariantOne from "audio/vo/BuzzInQuipVariantOne.mp3";
import VO_Winners from "audio/vo/Winners.mp3";
import VO_AllPassed from "audio/vo/AllPassed.mp3";

var audio = {

    Music: {
        Menu: {
            import: MenuMusic,
            loaded: null,
            volume: 0.2,
            loop: true,
            preventMultiple: true,
        },
        RoundOne: {
            import: RoundOneMusic,
            loaded: null,
            volume: 0.25,
            loop: true,
            preventMultiple: true,
        },
        RoundTwo: {
            import: RoundTwoMusic,
            loaded: null,
            volume: 0.25,
            loop: true,
            preventMultiple: true,
        },
        RoundThree: {
            import: RoundThreeMusic,
            loaded: null,
            volume: 0.25,
            loop: true,
            preventMultiple: true,
        },
        RoundSummary: {
            import: RoundSummaryMusic,
            loaded: null,
            volume: 0.25,
            loop: true,
            preventMultiple: true,
        },
        EndGame: {
            import: EndGameMusic,
            loaded: null,
            volume: 0.25,
            loop: true,
            preventMultiple: true,
        },
    },

    SFX: {
        Buzzer: {
            import: Buzzer,
            loaded: null,
            volume: 1,
            loop: false,
        },
        ButtonPress: {
            import: ButtonPress,
            loaded: null,
            volume: 0.2,
            loop: false,
        },
        CorrectAnswer: {
            import: CorrectAnswer,
            loaded: null,
            volume: 0.8,
            loop: false,
        },
        IncorrectAnswer: {
            import: IncorrectAnswer,
            loaded: null,
            volume: 0.8,
            loop: false,
        },
        RoundEnd: {
            import: RoundEnd,
            loaded: null,
            volume: 0.8,
            loop: false,
        },
        Winners: {
            import: Winners,
            loaded: null,
            volume: 0.8,
            loop: false,
        },
        Tik: {
            import: Tik,
            loaded: null,
            volume: 0.6,
            loop: false,
        },
        Tok: {
            import: Tok,
            loaded: null,
            volume: 0.6,
            loop: false,
        },
        TimesUpBell: {
            import: TimesUpBell,
            loaded: null,
            volume: 0.8,
            loop: false,
        },
        Pass: {
            import: Pass,
            loaded: null,
            volume: 0.8,
            loop: false,
        },
    },

    VO: {
        /* Voice Overs ID: dyTPmGzuLaJM15vpN3DS */
        Welcome: {
            import: VO_Wecome, // Hello! and welcome to Catchphrase!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        TeamSelect: {
            import: VO_TeamSelect, //Ok, Talk amongst yourselves and lets get into teams.
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        RoundOneIntro: {
            import: VO_RoundOneIntro, //Lets start with "Point Pursuit"! we'll show you some Catchphrases, just buzz in when you think you know the answer. But, give me a wrong answer and you're frozen out! Good luck! Let's play "Catchphrase"!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        RoundTwoIntro: {
            import: VO_RoundTwoIntro, //Now it's time for the "Quick Fire round!". We're going to be firing Catchphrases at you thick and fast. You can buzz in as often as you like. Just keep pressing and guessing till you get it right!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        RoundThreeIntro: {
            import: VO_RoundThreeIntro, //Right now, this is the big one. We're into the "Crunch Time Round!" where the phrases really start to pay. Everyone can answer these, but the faster you are the more points you get. So don't give it away and fingers on buzzers!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        RoundOneSummary: {
            import: VO_RoundOneSummary, //Always remember on Catchphrase, no matter what the scores are at this point, it could all change very soon.
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        RoundTwoSummary: {
            import: VO_RoundTwoSummary, //It's all looking good for the team on top, but all can change very quickly.
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        RoundThreeSummary: {
            import: VO_RoundThreeSummary, //Excellent guessing everyone, you all played like champions, but someones got to be the winner!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        Incorrect: {
            preventMultiple: true,
            currentVariantIndex: 0,
            variants: [
                {
                    import: VO_IncorrectVariantOne, //No, it's not that.
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_IncorrectVariantTwo, //Ooh sorry, that's not right.
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_IncorrectVariantThree, //Not a bad guess, but its not what we're looking for.
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                //TODO
                //{
                //    import: VO_IncorrectVariantFour, //I can see where you're coming from.
                //    loaded: null,
                //    volume: 1,
                //    loop: false,
                //},
                {
                    import: VO_IncorrectVariantFive, //Ah, Not quite!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
            ],
        },
        TimedOut: {
            preventMultiple: true,
            currentVariantIndex: 0,
            variants: [
                {
                    import: VO_TimedOutVariantOne, //Uh oh, not quite quick enough there.
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_TimedOutVariantTwo, //Oh no! you took a bit too long there.
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
            ],
        },
        Correct: {
            preventMultiple: true,
            currentVariantIndex: 0,
            variants: [
                {
                    import: VO_CorrectVariantOne, //You're correct!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_CorrectVariantTwo, //That's right!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_CorrectVariantThree, //Correct!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_CorrectVariantFour, //Your on fire!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_CorrectVariantFive, //We'll accept that answer!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
            ],
        },
        RoundTimesUp: {
            preventMultiple: true,
            currentVariantIndex: 0,
            variants: [
                {
                    import: VO_RoundTimesUpVariantOne, //Times up folks! You were so close!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_RoundTimesUpVariantTwo, //ooh, looks like time's up!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_RoundTimesUpVariantThree, //We're out of time on this one!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
            ],
        },
        PhraseQuip: {
            preventMultiple: true,
            currentVariantIndex: 0,
            variants: [
                {
                    import: VO_PhraseQuipVariantOne, //The clue is on the screen but what does it mean?
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_PhraseQuipVariantTwo, //Will you figure it out, lets find out!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_PhraseQuipVariantThree, //Is this one going to be yours? Let’s find out...
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_PhraseQuipVariantFour, //Fingers back on your buzzers.
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_PhraseQuipVariantFive, //Right, here we go! Good luck.
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_PhraseQuipVariantSix, //Whose gonna get this one. 
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
            ],
        },
        MidRoundQuip: {
            preventMultiple: true,
            currentVariantIndex: 0,
            variants: [
                {
                    import: VO_MidRoundQuipVariantOne, //What is that?
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_MidRoundQuipVariantTwo, //What’s going on here?
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_MidRoundQuipVariantThree, //All the clues are there!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_MidRoundQuipVariantFour, //What can you see?
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_MidRoundQuipVariantFive, //Think about it, what could it be?
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_MidRoundQuipVariantSix, //What are you seeing?
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_MidRoundQuipVariantSeven, //It's on the tip of your tongue!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_MidRoundQuipVariantEight, //What's the answer?
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_MidRoundQuipVariantNine, //Keep it up you've nearly got it!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_MidRoundQuipVariantTen, //Dont give up just yet!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
            ],
        },
        TimesNearlyUp: {
            preventMultiple: true,
            currentVariantIndex: 0,
            variants: [
                {
                    import: VO_TimesNearlyUpVariantOne, //Look out times running out!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
            ]
        },
        BuzzInQuip: {
            preventMultiple: true,
            currentVariantIndex: 0,
            variants: [
                {
                    import: VO_BuzzInQuipVariantOne, //Let's hear it!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
            ]
        },
        LockedOut: {
            import: VO_LockedOut, //Your now locked out.
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        BonusBoardIntro: {
            import: VO_BonusBoardIntro, //Ok back to the "Bonus Board"!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        BonusBoardReturn: {
            import: VO_BonusBoardReturn, //Time for the Bonus Board!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        AllLockedGridIntro: {
            import: VO_AllLockedGridIntro, //Since no one got the last Catchphrase, your all locked out, so take a good look there will be another chance to get it!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        AllTeamsGridIntro: {
            import: VO_AllTeamsGridIntro, //Last chance to get the bonus! No one got the last Catchphrase, so its a free for all, good luck!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        OneTeamGridIntro: {
            import: VO_OneTeamGridIntro, //Whomever got the last Catchphrase, here's your chance. The rest of you are locked out!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        FirstPhraseIntro: {
            import: VO_FirstPhraseIntro, //Lets get started with our first Catchphrase!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
        NextPhraseIntro: {
            preventMultiple: true,
            currentVariantIndex: 0,
            variants: [
                {
                    import: VO_NextPhraseIntroVariantOne, //Onto the next Catchphrase!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_NextPhraseIntroVariantTwo, //Lets bring in the next Catchphrase!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_NextPhraseIntroVariantThree, //Here's another Catchphrase!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_NextPhraseIntroVariantFour, //Let's move on to another Catchphrase!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
                {
                    import: VO_NextPhraseIntroVariantFive, //Here's you're next Catchphrase!
                    loaded: null,
                    volume: 1,
                    loop: false,
                },
            ]
        },
        Winners: {
            import: VO_Winners, //What a game! Congratulations and Commiserations to all our players! Thanks for playing Catchphrase! Would you like to play again or return to the lobby?
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true
        },
        AllPassed: {
            import: VO_AllPassed, //Ok lets move on!
            loaded: null,
            volume: 1,
            loop: false,
            preventMultiple: true,
        },
    }
};

export {
    audio,
}